import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

import './Input.scss'

const Input = ({
                   input,
                   meta,
                   label,
                   id,
                   placeholder,
                   variant,
                   endAdornment,
                   multiline = false,
                   className = '',
                   disabled = false
               }) => {
    return (
        <TextField
            {...input}
            id={id}
            label={label}
            variant={variant}
            InputProps={{
                endAdornment: endAdornment,
                classes: {root: 'field-input-root'}
            }}
            disabled={disabled}
            fullWidth
            multiline={multiline}
            placeholder={placeholder}
            error={(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.touched}
            helperText={`${meta.error && meta.touched ? meta.error.message : (meta.submitError && !meta.dirtySinceLastSubmit && meta.touched) ? meta.submitError.message : ''}`}
            className={`field-input ${className ? className + " " : ""}field-input${variant ? "-" + variant : ""} ${(meta.error || (meta.submitError && !meta.dirtySinceLastSubmit)) && meta.touched ? "is-error" : ""}`}
            FormHelperTextProps={{classes: {root: 'field-input-error'}}}
        />

    );
};

Input.propTypes =
    {
        variant: PropTypes.string.isRequired
    }

export default Input;
