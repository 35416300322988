import React from 'react';
import {NavHashLink} from 'react-router-hash-link';

const Logo = ({imgSrc, className}) => {
    const closeSidebar = () => {
        let sidebarContent = document.getElementsByClassName('sidebar-main'),
            navBtn = document.getElementsByClassName('menu-trigger');
        if (sidebarContent[0].classList.contains('is-open')) {
            navBtn[0].classList.remove('is-open')
            sidebarContent[0].classList.remove('is-open')
            document.body.classList.remove('no-scroll')
        }
    }
    return (
        <NavHashLink smooth to={'/'} className={`logo-link${className ? " " + className : ""}`}
                     onClick={closeSidebar}>
            <img src={imgSrc} alt="Logo"/>
        </NavHashLink>
    );
};

export default Logo;
