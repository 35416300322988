import React from 'react';

import {Link} from 'react-router-dom'

import {useTranslation} from "react-i18next";

import img1 from 'assests/images/history/history-image1.jpg'
import img2 from 'assests/images/history/history-image2.jpg'
import ArrowRightIcon from "icons/ArrowRightIcon";

import './History.scss'


const History = () => {
    const {t} = useTranslation();

    return (
        <section className="section-history" id="sectionCultureAndHistory">
            <div className="section-history__breadcrumb">
                <p className="section-history__breadcrumb-text">/ {t('history.breadcrumb')}</p>
            </div>
            <div className="section-history__content">
                <div className="section-history__content-info">
                    <div className="section-history__info-story">
                        <h2 className="section-history__story-title"> {t('history.title')}</h2>
                        <p className="section-history__story-text">{t('history.description')}</p>
                        <div className="section-history__info-more">
                            <Link className="section-history__info-more-link" to="/history">
                                      <span className="icon-arrow-right">
                                        <ArrowRightIcon/>
                                     </span>
                                <span>{t('history.more')}</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="section-history__content-gallery">
                    <div className="section-history__gallery-image">
                        <img src={img1} className="section-history__gallery-image-pic" alt="history-image1.png"/>
                    </div>
                    <div className="section-history__gallery-image">
                        <img src={img2} className="section-history__gallery-image-pic" alt="history-image2.png"/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default History;