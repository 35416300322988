import React from 'react';
import {useTranslation} from "react-i18next";

import LogoImageBlack from "assests/logo/logo-black.svg";
import Sidebar from "../Sidebar/Sidebar";
import SocialMedia from "components/SocialMedia/SocialMedia";

import './Footer.scss'
import Logo from "../Logo/Logo";


const Footer = () => {
    const {t} = useTranslation();
    return (
        <footer className="footer-main">
            <div className="footer-main__inner">
                <div className="footer-main__logo">
                   <Logo imgSrc={LogoImageBlack} className="footer-main__logo-link"/>
                </div>
                <div className="footer-main__navigation">
                    <div className="footer-main__menu">
                        <Sidebar/>
                    </div>
                    <div className="footer-main__social-media">
                        <SocialMedia title={t("socialMedia.footerTitle")}/>
                    </div>
                </div>
            </div>
            <div className="footer-main__bottom">
                <div className="container">
                    <p className="footer-main__bottom-copy-right">
                        © Stepanavan. All Rights Reserved 2021
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
