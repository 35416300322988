import i18n from "i18next";
import {initReactI18next} from "react-i18next";

import translationEN from 'languages/en/translation.json';
import translationARM from 'languages/arm/translation.json';
import translationRu from 'languages/ru/translation.json';

import {
    format as formatDate,
    isDate
} from "date-fns";

import {enUS, ru, hy} from "date-fns/locale";

const locales = {en: enUS, ru: ru, arm: hy};

// the translations
const resources = {
    en: {
        translation: translationEN
    },
    arm: {
        translation: translationARM
    },
    ru: {
        translation: translationRu
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false,
            format: (value, format, lng) => {
                if (isDate(value)) {
                    const locale = locales[lng];
                    return formatDate(value, 'EEEE dd MMM. yyyy ', {locale});
                }
                return value;
            }
        }

    });

export default i18n;