import React from 'react';

import Footer from "./Layout/Footer/Footer";
import Header from "./Layout/Header/Header";
import OffCanvasSidebar from "./Layout/OffCanvasSidebar/OffCanvasSidebar";

const Main = ({children}) => {

    return (
        <>
            <Header/>
            <OffCanvasSidebar/>
            <div className="content-main">
                {children}
            </div>
            <Footer/>
        </>
    );
};

export default Main;
