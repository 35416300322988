import React, {useEffect, useState} from 'react';
import {Link} from 'react-scroll'
import {connect} from "react-redux";
import i18n from "i18next";
import {useTranslation} from "react-i18next";

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import './Sidebar.scss'



const Sidebar = ({categories}) => {
    const {t} = useTranslation();
    const [orientation, setOrientation] = useState("vertical");
    const [value, setValue] = React.useState(0);
    const handleResize = () => {
        if (window.innerWidth < 992) {
            setOrientation("horizontal")
        } else {
            setOrientation("vertical")
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        if (window.innerWidth < 992) {
            setOrientation("horizontal")
        }

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const a11yProps = (index) => {
        return {
            id: `${orientation}-tab-${index}`,
            'aria-controls': `${orientation}-tabpanel-${index}`,
        };
    }
    const handleChangeValue = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <Tabs
            orientation={orientation}
            indicatorColor="primary"
            variant="scrollable"
            value={value}
            onChange={handleChangeValue}
            scrollButtons="auto"
            className="section-quality__sidebar-items"
            classes={{scrollButtons:'section-quality__sidebar-scroll-buttons'}}
        >
            {categories && categories.map((item) => {
                return (
                    <Tab key={item.id} activeClass="active"
                         label={i18n.language === "arm" ? item.name_am : i18n.language === "ru" ? item.name_ru : item.name}
                         component={Link}
                         to={`#${item.name}`}
                         duration={500}
                         smooth={true}
                         offset={-60}
                         className="section-quality__sidebar-item"
                         {...a11yProps(0)}
                         classes={{wrapper: "quality-wrapper", selected: "is-active"}}
                    />
                )
            })}
        </Tabs>
    );
};
const mapStateToProps = (state) => ({
    categories: state.quality.categories
})

export default connect(mapStateToProps)(Sidebar);

