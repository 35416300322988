import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {StickyContainer, Sticky} from 'react-sticky';

import img from 'assests/images/quality-of-life/quality.jpg'

import Sidebar from "./Sidebar/Sidebar";
import Accordion from "./Accordion/Accordion";
import {getCategories} from "actions/qualityAction";
import './QualityOfLifePage.scss'
import {useLocation} from "react-router-dom";


const QualityOfLifePage = ({categories, getCategories}) => {
    const {t} = useTranslation();
    const [isMobile, setIsMobile] = useState(false)
    const location = useLocation();
    const getAllCategories = () => {
        if (!categories) {
            getCategories()
        }
    }
    const handleResize = () => {
        let content = document.getElementsByClassName('section-quality');
        content[0].classList[window.innerWidth < 992 ? 'add' : 'remove']('is-mobile')
        setIsMobile(window.innerWidth < 992 ? true : false)
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        getAllCategories()
        handleResize()

        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <section className="section-quality">
            <div className="section-quality__banner">
                <div className="section-quality__banner-image">
                    <img src={img} alt={"quality.png"}/>
                    <div className="section-quality__banner-image-overly"></div>
                </div>
                <div className="section-quality__banner-text">
                    <div className="container">
                        <div className="section-quality__banner-text-content">
                            {t("quality.title")}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-quality__content">
                <div className="container">
                    <div className="section-quality__content-inner">
                        <div className="section-quality__sidebar">
                            <StickyContainer className="section-quality__sidebar-content">
                                <Sticky>{({
                                              style,
                                              distanceFromTop,
                                              calculatedHeight
                                          }) => {
                                    if (!isMobile) {
                                        return (
                                            <div className="section-quality__sidebar-sticky" style={style}>
                                                <Sidebar/>
                                            </div>
                                        )
                                    }

                                    return (
                                        <div className="section-quality__sidebar-sticky">
                                            {distanceFromTop < 0 ? (
                                                <div className="section-quality__sidebar-sticky-content"
                                                     style={{height: calculatedHeight + 'px'}}></div>
                                            ) : ''}
                                            <div className={distanceFromTop < 0 ? 'is-sticky' : ''}>
                                                <Sidebar/>
                                            </div>
                                        </div>
                                    )
                                }
                                }</Sticky>
                            </StickyContainer>
                        </div>
                        <div className="section-quality__categories">
                            {categories && categories.map((item) => {
                                return (
                                    <div key={item.id} className="section-quality__category" id={`#${item.name}`}>
                                        <div className="section-quality__category-info">
                                            <div
                                                className="section-quality__category-info-breadcrumb">{i18n.language === "arm" ? item.name_am : i18n.language === "ru" ? item.name_ru : item.name}</div>
                                            <h2 className="section-quality__category-info-title"> {i18n.language === "arm" ? item.title_am : i18n.language === "ru" ? item.title_ru : item.title}</h2>
                                            <p className="section-quality__category-info-desc"><b>{t('quality.cityTarget')}</b> {i18n.language === "arm" ? item.content_am : i18n.language === "ru" ? item.content_ru : item.content}</p>
                                        </div>
                                        <div className="section-quality__accordion">
                                            <Accordion categoryid={item.id}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    categories: state.quality.categories
})


const mapDispatchToProps = {
    getCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(QualityOfLifePage);
