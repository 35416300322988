import React from 'react';
import InstagramIcon from "../../icons/InstagramIcon";
import FacebookIcon from "../../icons/FacebookIcon";

import './SocialMedia.scss'

const SocialMedia = ({title = "follow us"}) => {
    return (
        <div className="social-media">
            <div className="social-media__content">
                <span className="social-media__title">{title}</span>
                <ul className="list-unstyled social-media__list">
                    <li className="social-media__item">
                        <a href="https://www.instagram.com/visitstepanavan/" target="_blank" rel="noreferrer" className="social-media__link">
                            <InstagramIcon/>
                        </a>
                    </li>
                    <li className="social-media__item">
                        <a href="https://www.facebook.com/visitstepanavan" target="_blank" rel="noreferrer" className="social-media__link">
                            <FacebookIcon/>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default SocialMedia;
