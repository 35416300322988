import React from 'react';

const TickIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M60.2885 74.5192C54.0705 78.1731 47.3077 80 40 80C32.6923 80 25.9295 78.1731 19.7115 74.5192C13.6859 70.9936 8.94231 66.2179 5.48077 60.1923C1.82692 53.9744 0 47.2436 0 40C0 32.7564 1.82692 26.0256 5.48077 19.8077C9.00641 13.7821 13.7821 9.00641 19.8077 5.48077C26.0256 1.82692 32.7564 0 40 0C47.3077 0 54.0705 1.82692 60.2885 5.48077C66.3141 8.94231 71.0577 13.6859 74.5192 19.7115C78.1731 25.9295 80 32.6923 80 40C80 47.3077 78.1731 54.0705 74.5192 60.2885C71.0577 66.3141 66.3141 71.0577 60.2885 74.5192ZM14.8306 42.8385L31.9231 60.0962L65.1461 26.8731C66.7192 25.3 66.7404 22.7561 65.1937 21.157C63.6084 19.5179 60.9868 19.4981 59.377 21.1131L31.9231 48.6538L20.5308 37.1846C18.9647 35.6079 16.4156 35.6036 14.8442 37.1751C13.2818 38.7374 13.2757 41.2686 14.8306 42.8385Z"
                  fill="#02C674"/>
        </svg>
    );
}

export default TickIcon;