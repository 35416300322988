import React from "react";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from 'reducers'

import AppRoutes from "routes/AppRoutes";


import 'simplebar/dist/simplebar.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './styles/font.scss';
import './styles/App.css';

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <AppRoutes/>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
