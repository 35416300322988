import React from 'react';

const FacebookIcon = () => {
    return (
        <svg width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.81344 3.99023L8.85279 4.63903L8.19702 4.55958C5.81005 4.25505 3.72473 3.22228 1.95417 1.48775L1.08856 0.627107L0.865606 1.26266C0.393457 2.67941 0.695108 4.1756 1.67875 5.18189C2.20336 5.738 2.08532 5.81745 1.18037 5.48643C0.865606 5.3805 0.590186 5.30106 0.563955 5.34078C0.472149 5.43347 0.786914 6.63837 1.0361 7.11503C1.3771 7.77706 2.07221 8.42586 2.83289 8.80983L3.47554 9.11437L2.71485 9.12761C1.9804 9.12761 1.95417 9.14085 2.03286 9.41891C2.29517 10.2795 3.33127 11.1932 4.48541 11.5904L5.29856 11.8684L4.59033 12.2921C3.54111 12.9012 2.30828 13.2455 1.07545 13.2719C0.485264 13.2852 0 13.3381 0 13.3779C0 13.5103 1.60006 14.2517 2.53124 14.543C5.32479 15.4037 8.64294 15.0329 11.1348 13.5632C12.9054 12.5172 14.676 10.4384 15.5022 8.42586C15.9481 7.35336 16.394 5.39374 16.394 4.45366C16.394 3.84459 16.4334 3.76514 17.1678 3.03691C17.6007 2.61321 18.0072 2.14978 18.0859 2.01738C18.2171 1.7658 18.204 1.7658 17.5351 1.9909C16.4203 2.38811 16.2629 2.33515 16.8137 1.73932C17.2203 1.31562 17.7056 0.547663 17.7056 0.322572C17.7056 0.28285 17.5088 0.349054 17.2859 0.46822C17.0498 0.600626 16.5252 0.799236 16.1317 0.918402L15.4235 1.14349L14.7809 0.706551C14.4268 0.468219 13.9284 0.203406 13.6661 0.123962C12.9972 -0.0614069 11.9742 -0.0349255 11.3709 0.176925C9.73151 0.772755 8.6954 2.30867 8.81344 3.99023Z" fill="currentColor"/>
        </svg>
    );
};

export default FacebookIcon;
