import React from 'react';

import {NavHashLink} from 'react-router-hash-link';

import ArrowRightIcon from "icons/ArrowRightIcon";

import {navigationListOne, navigationListTwo} from './NavigationLists'

import './Sidebar.scss'
import {useTranslation} from "react-i18next";

const Sidebar = () => {
    const {t} = useTranslation();

    const closeSidebar = () => {

        let sidebarContent = document.getElementsByClassName('sidebar-main'),
            navBtn = document.getElementsByClassName('menu-trigger');
        if (sidebarContent[0].classList.contains('is-open')) {
            navBtn[0].classList.remove('is-open')
            sidebarContent[0].classList.remove('is-open')
            document.body.classList.remove('no-scroll')
        }
    }

    return (
        <div className="sidebar-navigation">
            <div className="sidebar-navigation__content">
                <ul className="list-unstyled sidebar-navigation__list">
                    {
                        navigationListOne.map((item, index) => (
                            <li className="sidebar-navigation__list-item" key={index}>
                                <NavHashLink smooth to={item.to} className="sidebar-navigation__link" activeClassName="is-active"
                                          onClick={closeSidebar}>
                                    <span className="icon-arrow-right">
                                        <ArrowRightIcon/>
                                    </span>
                                    <span> {t(item.title)}</span>
                                </NavHashLink>
                            </li>
                        ))
                    }
                </ul>
                <div className="sidebar-navigation__line">
                    <span></span>
                </div>
                <ul className="list-unstyled sidebar-navigation__list">
                    {
                        navigationListTwo.map((item, index) => (
                            <li className="sidebar-navigation__list-item" key={index}>
                                <NavHashLink smooth to={item.to} className="sidebar-navigation__link" activeClassName="is-active"
                                          onClick={closeSidebar}>
                                    <span className="icon-arrow-right">
                                        <ArrowRightIcon/>
                                    </span>
                                    <span> {t(item.title)}</span>
                                </NavHashLink>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
