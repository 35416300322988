import service from "api/service";

import {reduxActionTypes} from "reducers/reduxActionTypes";

export const getCategories = () => (dispatch) => {
    return service.qualityService.getCategories().then((res) => {
        if (res && res.status === 200) {
            dispatch({
                type: reduxActionTypes.GET_QUALITY_CATEGORY,
                payload: {
                    categories: res.data,
                }
            })
        }
    })
}


export const getItems = (data) => (dispatch) => {
    return service.qualityService.getItems(data).then((res) => {
        return res
    })
}
