import React from 'react';

const FacebookIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0 10C0 4.48602 4.48602 0 10 0C15.5144 0 20 4.48602 20 10C20 15.5136 15.5136 20 10 20C4.48602 20 0 15.5136 0 10ZM10.86 10.3521H12.4869L12.6976 8.30299H10.8608V7.09861C10.8608 6.64585 11.1609 6.54032 11.3719 6.54032H12.6686V4.55087L10.8821 4.54403C8.90035 4.54403 8.44921 6.02755 8.44921 6.97696V8.30259H7.30323V10.3521H8.44921V16.1508H10.86V10.3521Z"
                  fill="currentColor"/>
        </svg>
    );
};

export default FacebookIcon;
