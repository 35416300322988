import React, {useState} from 'react';
import {Field, Form} from "react-final-form";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import Input from "components/Fields/Input/Input";
import Loader from "components/Loader/Loader";
import FailIcon from "icons/FailIcon";
import TickIcon from "icons/TickIcon";

import contactFormValidate from "utils/contactFormValidate";

import {sendMail} from "actions/contactActions";

import './Contact.scss'

const Contact = ({sendMail}) => {

    const {t} = useTranslation();

    const [modalConfig, setModalConfig] = useState({
        isOpenSuccessModal: false,
        isOpenFailModal: false,
    });

    const [loading, setLoading] = useState(false)

    const onSubmit = async (formData) => {
        setLoading(true)
        document.body.classList.add('no-scroll')

        const res = await sendMail(formData);

        if (res && res.status !== 200) {
            setLoading(false)
            document.body.classList.remove('no-scroll')
            openModal('isOpenFailModal')

            let errors = {}

            if (res.data) {
                Object.keys(res.data).map((item) => {
                    return errors[item] = {code: "invalid", message: res.data[item]}
                })
            }

            return errors
        } else {
            openModal('isOpenSuccessModal')
            setLoading(false)
            document.body.classList.remove('no-scroll')
        }
    }


    const openModal = (name) => {
        setModalConfig({
            ...modalConfig,
            [name]: true,
        });
    };

    const closeModal = (name) => {
        setModalConfig({
            ...modalConfig,
            [name]: false,
        });
    };

    return (
        <section className="section-contact" id="sectionContact">
            {loading && (
                <Loader className="content-main-loader" size={60}/>
            )}
            <div className="container">
                <div className="section-contact__inner">
                    <div className="section-contact__content">
                        <div className="section-contact__info">
                            <h2 className="section-contact__info-title">
                                {t("contact.title")}
                            </h2>
                            <p className="section-contact__info-desc">
                                {t("contact.description")}
                            </p>
                        </div>
                        <Form onSubmit={onSubmit} validate={contactFormValidate}
                              render={({handleSubmit}) => (
                                  <form className="section-contact__from"
                                        onSubmit={handleSubmit}>
                                      <div className="section-contact__from-fields">
                                          <Field
                                              name="email"
                                              type="text"
                                              label={t("contact.placeholder")}
                                              id="contactEmailAddress"
                                              variant="outlined"
                                              component={Input}
                                          />
                                      </div>
                                      <div className="section-contact__from-button-container">
                                          <Button
                                              type="submit"
                                              color="primary"
                                              variant="contained"
                                              size="large"
                                              className="section-contact__from-button">
                                              {t("contact.button")}
                                          </Button>
                                      </div>
                                  </form>
                              )}/>
                    </div>
                    <Dialog
                        open={modalConfig.isOpenSuccessModal}
                        onClose={() => {
                            closeModal('isOpenSuccessModal')
                        }}
                        fullWidth
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        classes={{root: "section-contact__modal", paper: "section-contact__modal-paper"}}
                    >
                        <DialogContent className="section-contact__modal-content">
                            <IconButton onClick={() => {
                                closeModal('isOpenSuccessModal')
                            }} className="section-contact__modal-close-btn">
                                <CloseIcon/>
                            </IconButton>
                            <div className="section-contact__modal-title">
                                {t("contact.successMessage.title")}
                            </div>
                            <div className="section-contact__modal-desc">
                                <span className="section-contact__modal-icon">
                                    <TickIcon/>
                                </span>
                                <p>{t("contact.successMessage.desc")}</p>
                            </div>
                            <Button variant="contained" onClick={() => {
                                closeModal('isOpenSuccessModal')
                            }} className="section-contact__modal-btn" color="primary">
                                {t("contact.modal.btn")}
                            </Button>
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={modalConfig.isOpenFailModal}
                        onClose={() => {
                            closeModal('isOpenFailModal')
                        }}
                        fullWidth
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        classes={{root: "section-contact__modal", paper: "section-contact__modal-paper"}}
                    >
                        <DialogContent className="section-contact__modal-content">
                            <IconButton onClick={() => {
                                closeModal('isOpenFailModal')
                            }} className="section-contact__modal-close-btn">
                                <CloseIcon/>
                            </IconButton>
                            <div className="section-contact__modal-title">
                                {t("contact.failMessage.title")}
                            </div>
                            <div className="section-contact__modal-desc">
                        <span className="section-contact__modal-icon">
                            <FailIcon/>
                        </span>

                                <p> {t("contact.failMessage.title")}</p>
                            </div>
                            <Button variant="contained" onClick={() => {
                                closeModal('isOpenFailModal')
                            }} className="section-contact__modal-btn" color="primary">
                                ok
                            </Button>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        </section>
    );
};
const mapDispatchToProps = {
    sendMail,
};

export default connect(null, mapDispatchToProps)(Contact);

