import React from 'react';
import {Splide, SplideSlide} from "@splidejs/react-splide";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";

import MapIcon from "icons/MapIcon";

import './OneItemSlider.scss'
import imageWebp from "../../assests/images/business/business.webp";
import image from "../../assests/images/business/business.jpg";


const OneItemSlider = ({options, items}) => {
    const {t} = useTranslation();
    const newOptions = {
        ...options,
        classes: {
            // Add classes for arrows.
            arrows: 'splide__arrows one-item-slider__arrows',
            arrow: 'splide__arrow one-item-slider__arrow',
            prev: 'splide__arrow--prev one-item-slider__prev',
            next: 'splide__arrow--next one-item-slider__next',

            // Add classes for pagination.
            pagination: 'splide__pagination one-item-slider__pagination', // container
            page: 'splide__pagination__page one-item-slider__page', // each button
        },
        arrowPath: 'M10.8711 40C10.3909 40.0009 10.0009 39.6122 10 39.132C9.99959 38.9005 10.0915 38.6783 10.2555 38.5148L28.7715 20.0005L10.2555 1.48628C9.91547 1.14627 9.91547 0.595031 10.2555 0.255013C10.5955 -0.0850044 11.1467 -0.0850044 11.4868 0.255013L30.6167 19.3849C30.9561 19.7245 30.9561 20.2749 30.6167 20.6145L11.4868 39.7444C11.3237 39.908 11.1021 40 10.8711 40Z'
    }

    return (
        <Splide
            options={newOptions} className="one-item-slider">
            {items.map((el, index) => {
                return (
                    <SplideSlide key={index} className="one-item-slider__item">
                        <picture>
                            <source srcSet={el.img.webp} type="image/webp"/>
                            <img src={el.img.src} alt={el.img.alt} className="one-item-slider__item-image"/>
                        </picture>
                        <div className="one-item-slider__item-info">
                            <div className="one-item-slider__item-container">
                                <div className="one-item-slider__item-content">
                                    <h2 className="one-item-slider__item-info-title">{t(el.title)}</h2>
                                    <p className="one-item-slider__item-desc">{t(el.desc)}</p>
                                    <a href={t(el.link)} target="_blank" className="one-item-slider__item-link"
                                       rel="noreferrer">
                                        <span className="icon-map">
                                            <MapIcon/>
                                        </span>
                                        <span>{t("explore.mapTitle")}</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </SplideSlide>
                )
            })}
        </Splide>
    );
};

OneItemSlider.propTypes = {
    options: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
}


export default OneItemSlider;
