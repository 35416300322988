import img1 from "assests/images/why-stepanavan/01.jpg";
import img2 from "assests/images/why-stepanavan/02.jpg";
import img3 from "assests/images/why-stepanavan/03.jpg";
import img4 from "assests/images/why-stepanavan/04.jpg";
import img5 from "assests/images/why-stepanavan/05.jpg";
import img6 from "assests/images/why-stepanavan/06.jpg";
import img7 from "assests/images/why-stepanavan/07.jpg";
import img8 from "assests/images/why-stepanavan/08.jpg";

export const whyStepanavanData = [
    {
        id: 1,
        img: {
            src: img1,
            alt: "Image 1"
        },
    },
    {
        id: 2,
        img: {
            src: img2,
            alt: "Image 2"
        },
    },
    {
        id: 3,
        img: {
            src: img3,
            alt: "Image 3"
        },
    },
    {
        id: 4,
        img: {
            src: img4,
            alt: "Image 4"
        },
    },
    {
        id: 5,
        img: {
            src: img5,
            alt: "Image 5"
        },
    },
    {
        id: 6,
        img: {
            src: img6,
            alt: "Image 6"
        },
    },
    {
        id: 7,
        img: {
            src: img7,
            alt: "Image 7"
        },
    },
    {
        id: 8,
        img: {
            src: img8,
            alt: "Image 8"
        },
    }

]
