import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import i18n from "i18next";

import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import {Accordion as MaterialAccordion} from '@material-ui/core';

import {getItems} from "actions/qualityAction";

import {useTranslation} from "react-i18next";

import './Accordion.scss'

const Accordion = ({categoryid, getItems}) => {
    const {t} = useTranslation();
    const [expanded, setExpanded] = React.useState(false);

    const [list, setList] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getAllItems = async () => {
        if (categoryid) {
            const res = await getItems(categoryid)
            setList(res.data)
        }
    }

    useEffect(() => {
        getAllItems()
    }, []);

    return (
        <>
            {list && list.map((item) => {
                return (
                    <MaterialAccordion key={item.id} expanded={expanded === `panel1${item.id}`}
                                       onChange={handleChange(`panel1${item.id}`)}
                                       className="app-accordion" classes={{expanded: "is-expanded"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon className="app-accordion__title-icon"/>}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className="app-accordion__title"
                            classes={{expanded: "is-expanded", expandIcon: "app-expandIcon"}}
                        >
                            <div style={{width: item.value + '%'}}
                                 className={`app-accordion__title-progress${item.value <= 30 ? " is-bed" : item.value <= 70 ? " is-middle" : " is-good"}`}>
                                <span className="app-accordion__title-progress-count">{item.value}%</span>
                            </div>
                            <div className="app-accordion__title-info">
                                {item.year}
                            </div>
                        </AccordionSummary>
                        <AccordionDetails className="app-accordion__desc">
                            <div className="app-accordion__desc-content" dangerouslySetInnerHTML={{__html: i18n.language === "arm" ? item.content_am : i18n.language === "ru" ? item.content_ru : item.content}}></div>
                        </AccordionDetails>
                    </MaterialAccordion>)
            })
            }
        </>
    );
};
const mapDispatchToProps = {
    getItems
}

export default connect(null, mapDispatchToProps)(Accordion);
