const contactValidate = (values) => {
    let errors = {};

    let regEmail = /^[a-z.A-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/


    if (!values.email) {
        errors.email = {code: "email", message: "Please fill in this field"};
    } else if (!regEmail.test(values.email)) {
        errors.email = {code: "email", message: "Incorrect email"};
    }

    if (Object.keys(errors).length === 0) {
        return null;
    }

    return errors;
}
export default contactValidate;