import React from 'react';

import {useTranslation} from "react-i18next";

import img1 from 'assests/images/journey/journey1.jpg'
import img2 from 'assests/images/journey/journey2.jpg'
import img3 from 'assests/images/journey/journey3.jpg'


import './Journey.scss'

const Journey = () => {
    const {t} = useTranslation();
    return (
        <section className="section-journey">
            <div className="section-journey__content">
                <div className="section-journey__content-images">
                    <div className="section-journey__content-image">
                        <img src={img1} className="section-journey__image-pic" alt="journey1.png"/>
                    </div>
                    <div className="section-journey__content-image" >
                        <img src={img2} className="section-journey__image-pic" alt="journey2.png"/>
                    </div>
                    <div className="section-journey__content-image">
                        <img src={img3} className="section-journey__image-pic" alt="journey3.png"/>
                    </div>
                </div>
                <div className="section-journey__content-info">
                    <div className="section-journey__info-discover">
                        <h2 className="section-journey__discover-title">{t("journey.title")}</h2>
                        <p className="section-journey__discover-text is-first">{t("journey.text1")}</p>
                        <p className="section-journey__discover-text is-second">{t("journey.text2")}</p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Journey;
