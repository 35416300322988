import img1 from "assests/images/explore/01_Vardablur.jpg";
import imgWebp1 from "assests/images/explore/01_Vardablur.webp";
import img2 from "assests/images/explore/02_Arevatsagi_dzor.jpg";
import imgWebp2 from "assests/images/explore/02_Arevatsagi_dzor.webp";
import img3 from "assests/images/explore/03_hnevanq.jpg";
import imgWebp3 from "assests/images/explore/03_hnevanq.webp";
import img4 from "assests/images/explore/04_Stepanavan_Lakes.jpg";
import imgWebp4 from "assests/images/explore/04_Stepanavan_Lakes.webp";
import img5 from "assests/images/explore/05_Lori_gorge.jpg";
import imgWebp5 from "assests/images/explore/05_Lori_gorge.webp";
import img6 from "assests/images/explore/06_Dendropark.jpg";
import imgWebp6 from "assests/images/explore/06_Dendropark.webp";
import img7 from "assests/images/explore/07_Amrakis.jpg";
import imgWebp7 from "assests/images/explore/07_Amrakis.webp";
import img8 from "assests/images/explore/08_Lori_berd.jpg";
import imgWebp8 from "assests/images/explore/08_Lori_berd.webp";
import img9 from "assests/images/explore/09_Major_cave.jpg";
import imgWebp9 from "assests/images/explore/09_Major_cave.webp";

export const exploreData = [
    {
        id: 1,
        img: {
            webp: imgWebp1,
            src: img1,
            alt: "explore.items.item1.alt"
        },
        title: "explore.items.item1.title",
        desc: "explore.items.item1.description",
        link: "explore.items.item1.link"
    },
    {
        id: 2,
        img: {
            webp: imgWebp2,
            src: img2,
            alt: "explore.items.item2.alt"
        },
        title: "explore.items.item2.title",
        desc: "explore.items.item2.description",
        link: "explore.items.item2.link"
    },
    {
        id: 3,
        img: {
            webp: imgWebp3,
            src: img3,
            alt: "explore.items.item3.alt"
        },
        title: "explore.items.item3.title",
        desc: "explore.items.item3.description",
        link: "explore.items.item3.link"
    },
    {
        id: 4,
        img: {
            webp: imgWebp4,
            src: img4,
            alt: "explore.items.item4.alt"
        },
        title: "explore.items.item4.title",
        desc: "explore.items.item4.description",
        link: "explore.items.item4.link"
    },
    {
        id: 5,
        img: {
            webp: imgWebp5,
            src: img5,
            alt: "explore.items.item5.alt"
        },
        title: "explore.items.item5.title",
        desc: "explore.items.item5.description",
        link: "explore.items.item5.link"
    },
    {
        id: 6,
        img: {
            webp: imgWebp6,
            src: img6,
            alt: "explore.items.item6.alt"
        },
        title: "explore.items.item6.title",
        desc: "explore.items.item6.description",
        link: "explore.items.item6.link"
    },
    {
        id: 7,
        img: {
            webp: imgWebp7,
            src: img7,
            alt: "explore.items.item7.alt"
        },
        title: "explore.items.item7.title",
        desc: "explore.items.item7.description",
        link: "explore.items.item7.link"
    },
    {
        id: 8,
        img: {
            webp: imgWebp8,
            src: img8,
            alt: "explore.items.item8.alt"
        },
        title: "explore.items.item8.title",
        desc: "explore.items.item8.description",
        link: "explore.items.item8.link"
    },
    {
        id: 9,
        img: {
            webp: imgWebp9,
            src: img9,
            alt: "explore.items.item9.alt"
        },
        title: "explore.items.item9.title",
        desc: "explore.items.item9.description",
        link: "explore.items.item9.link"
    },
]
