import React from 'react';

const SafeCityIcon = () => {
    return (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path d="M23.8948 16.7275C25.5153 14.4139 26.3718 11.6979 26.3718 8.87335V2.57564C26.3718 2.26462 26.1558 1.9951 25.8523 1.9274L17.2775 0.0158806C17.1823 -0.00513077 17.0838 -0.00513077 16.9886 0.0158806L8.41411 1.9274C8.11035 1.9951 7.89453 2.26462 7.89453 2.57564V8.87335C7.89453 11.6979 8.75107 14.4139 10.3715 16.7272C11.992 19.0408 14.2516 20.7738 16.9063 21.7393C16.9795 21.7658 17.0565 21.7793 17.1331 21.7793C17.2098 21.7793 17.2869 21.7658 17.36 21.7393C20.0147 20.7741 22.2744 19.0408 23.8948 16.7275ZM17.1331 20.406C14.8343 19.5196 12.876 17.9881 11.4595 15.9653C9.99619 13.8764 9.22266 11.424 9.22266 8.87335V3.10793L17.1331 1.34453L25.0437 3.10793V8.87335C25.0437 11.4238 24.2702 13.8761 22.8069 15.9653C21.3901 17.9881 19.4319 19.5196 17.1331 20.406Z" fill="white"/>
                <path d="M23.9135 4.54573C23.9135 4.23445 23.6977 3.96519 23.3939 3.89749L17.2773 2.53382C17.1821 2.51281 17.0833 2.51281 16.9881 2.53382L10.8711 3.89749C10.5132 3.97738 10.2878 4.33224 10.3677 4.69022C10.4473 5.04819 10.8024 5.27361 11.1601 5.19371L17.1325 3.86247L22.5854 5.07802V8.8733C22.5854 10.9168 21.9657 12.8815 20.7935 14.5554C19.8516 15.9002 18.5904 16.9897 17.1325 17.7219C15.6747 16.9899 14.4138 15.9002 13.4719 14.5554C12.4579 13.1077 11.8488 11.4154 11.7108 9.66187C11.6992 9.51557 11.6909 9.36771 11.6859 9.22193C11.6732 8.85514 11.3637 8.5685 10.9993 8.58095C10.6328 8.59366 10.3459 8.90105 10.3586 9.26758C10.3643 9.43282 10.3736 9.60065 10.3866 9.76615C10.5433 11.756 11.234 13.6756 12.384 15.3173C13.5199 16.9388 15.0654 18.2337 16.8537 19.0617C16.9421 19.1027 17.0376 19.1232 17.1328 19.1232C17.228 19.1232 17.3232 19.1027 17.4117 19.0617C19.2002 18.2335 20.7457 16.9388 21.8814 15.3173C23.2108 13.4193 23.9138 11.1908 23.9138 8.8733V4.54573H23.9135Z" fill="white"/>
                <path d="M15.2413 12.3204C15.3575 12.4579 15.5243 12.5422 15.704 12.5542C15.7188 12.5552 15.7336 12.5557 15.7484 12.5557C15.9123 12.5557 16.0713 12.495 16.1938 12.3843L21.0082 8.02841C21.2801 7.7825 21.3011 7.36253 21.0552 7.09042C20.809 6.81857 20.3891 6.79756 20.1172 7.04347L15.8119 10.9384L14.5832 9.48494C14.3466 9.20505 13.9274 9.16977 13.6475 9.4066C13.3674 9.64343 13.3323 10.0624 13.5689 10.3425L15.2413 12.3204Z" fill="white"/>
                <path d="M11.5409 21.9773C11.191 21.5172 10.6823 21.2246 10.1088 21.153C9.53524 21.0816 8.97027 21.2401 8.51787 21.5999C8.3062 21.7683 8.13526 21.9701 8.00634 22.1924L7.81127 21.9382C7.63799 21.7128 7.54279 21.4321 7.54279 21.1478V15.1569C7.54279 13.9331 6.54695 12.9373 5.32311 12.9373C4.17189 12.9373 3.22197 13.8016 3.11328 14.9473L2.40252 22.4432C2.34001 23.0998 2.52522 23.7511 2.92599 24.2803L8.57053 31.6301V33.3359C8.57053 33.7027 8.8678 34 9.23459 34C9.60113 34 9.89866 33.7027 9.89866 33.3359V31.4044C9.89866 31.2581 9.85015 31.116 9.76118 31L3.98201 23.4749C3.78512 23.2149 3.69381 22.893 3.72468 22.5685L4.4357 15.0729C4.47928 14.6125 4.86085 14.2654 5.32311 14.2654C5.81493 14.2654 6.21466 14.6654 6.21466 15.1569V21.1478C6.21466 21.7231 6.40766 22.291 6.7581 22.747L8.14823 24.5573C8.1503 24.5602 8.15238 24.563 8.15445 24.5659L9.2761 26.0266C9.4997 26.3174 9.91656 26.3721 10.2073 26.1487C10.4981 25.9254 10.5529 25.5085 10.3295 25.2175L9.20425 23.7522C8.94355 23.4072 9.0058 22.9091 9.3451 22.6393C9.51552 22.5037 9.72849 22.444 9.94457 22.4707C10.1607 22.4977 10.3523 22.6082 10.4841 22.7815L13.165 26.3046C13.6926 26.998 13.9832 27.8597 13.9832 28.7311V33.3359C13.9832 33.7027 14.2804 34 14.6472 34C15.014 34 15.3113 33.7027 15.3113 33.3359V28.7311C15.3113 27.571 14.9245 26.4237 14.2221 25.5005L11.5409 21.9773Z" fill="white"/>
                <path d="M31.6766 22.4432L30.9656 14.9473C30.8569 13.8016 29.9067 12.9373 28.7558 12.9373C27.5319 12.9373 26.5361 13.9331 26.5361 15.1569V21.1478C26.5361 21.4321 26.4406 21.7128 26.2676 21.9382L26.0723 22.1924C25.9436 21.9703 25.7727 21.7685 25.561 21.5999C25.1086 21.2401 24.5436 21.0816 23.9701 21.153C23.3966 21.2246 22.8879 21.5172 22.538 21.9773L19.8571 25.5005C19.1546 26.4237 18.7676 27.571 18.7676 28.7313V33.3359C18.7676 33.7027 19.0651 34 19.4316 34C19.7984 34 20.0957 33.7027 20.0957 33.3359V28.7311C20.0957 27.8597 20.3862 26.998 20.9139 26.3046L23.5948 22.7815C23.7268 22.6082 23.9185 22.498 24.1346 22.4707C24.3506 22.444 24.5636 22.5037 24.734 22.6393C25.0731 22.9091 25.1356 23.4072 24.8746 23.7522L23.7496 25.2175C23.5263 25.5085 23.581 25.9254 23.8718 26.1487C24.1626 26.3721 24.5794 26.3174 24.803 26.0266L25.9244 24.5659C25.9265 24.5633 25.9283 24.5607 25.9304 24.5581L27.321 22.7472C27.6712 22.291 27.8642 21.7231 27.8642 21.1478V15.1569C27.8642 14.6654 28.2642 14.2654 28.7558 14.2654C29.218 14.2654 29.5996 14.6125 29.6434 15.0729L30.3545 22.5685C30.3851 22.8933 30.2938 23.2149 30.0995 23.4715L24.3177 31C24.2287 31.116 24.1805 31.2584 24.1805 31.4047V33.3359C24.1805 33.7027 24.4777 34 24.8445 34C25.2113 34 25.5086 33.7027 25.5086 33.3359V31.6301L31.1555 24.2769C31.5537 23.7511 31.7389 23.0998 31.6766 22.4432Z" fill="white"/>
                <path d="M10.6473 7.44402C10.881 7.59992 11.1975 7.59006 11.42 7.41859C11.6294 7.25725 11.7277 6.97554 11.6571 6.71977C11.5816 6.44559 11.3363 6.24429 11.0512 6.22873C10.7689 6.21342 10.5025 6.38566 10.399 6.64792C10.2854 6.93611 10.3905 7.27126 10.6473 7.44402Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="34" height="34" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};

export default SafeCityIcon;
