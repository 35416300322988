import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";

import image from 'assests/images/blog/blog-listing.png'

import BoxBlog from "components/Box/BoxBlog/BoxBlog";
import Loader from "components/Loader/Loader";

import {getAllItems, getItems, getMoreItems, setHasMore} from "actions/blogAction";

import './Blog.scss'

const take = 10;


const Blog = ({list, getItems, getMoreItems, getAllItems, hasMore, setHasMore}) => {
    const location = useLocation();
    const {t} = useTranslation();

    const [loading, setLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [totalItems, setTotalItems] = useState(0);


    const getItemList = async () => {
        if (!list) {
            await getItems(take, skip)
        }
        const res = await getAllItems()
        setTotalItems(res.length - 1)
        setHasMore(res && res.length > take ? true : false)

    }

    const getMore = async () => {
        const newSkip = skip + take;
        setLoading(true)
        setSkip(newSkip)
        console.log()
        setHasMore(totalItems === newSkip ? false : true )
        await getMoreItems(take, newSkip)
        setLoading(false)
    };

    useEffect(() => {
        getItemList()
        console.log(123)
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (!list) {
        return (
            <section className="section-blog-listing">
                <Loader/>
            </section>
        )
    }

    return (

        <section className="section-blog-listing">
            <div className="section-blog-listing__banner">
                <div className="section-blog-listing__banner-image">
                    <img src={image} alt={'Blog'}/>
                    <div className="section-blog-listing__banner-image-overly"></div>
                </div>
                <div className="section-blog-listing__banner-text">
                    <div className="container">
                        <div className="section-blog-listing__banner-text-content">
                            {t('blog.listing.title')}
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-blog-listing__content">
                <div className="container">
                    <div className="section-blog-listing__items">
                        {list.map((el, index) => {
                            return <BoxBlog key={index} item={el} className="section-blog-listing__item"/>
                        })}
                    </div>
                    {hasMore ? (
                        <div className="section-blog-listing__button">
                            {loading ? (
                                <Loader/>
                            ) : (
                                <button className="section-blog-listing__button-link" onClick={getMore}>
                                    <span className="section-blog-listing__button-link-text">{t('blog.listing.moreBtn')}</span>
                                </button>
                            )}
                        </div>
                    ) : ("")}
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    list: state.blog.list,
    hasMore: state.blog.hasMore
})

const mapDispatchToProps = {
    getItems,
    getMoreItems,
    getAllItems,
    setHasMore
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);