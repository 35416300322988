import React from 'react';

const FacebookIconTwo = () => {
    return (
        <svg width="10" height="20" viewBox="0 0 10 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9.002 4.14678C8.4839 4.04316 7.78417 3.96575 7.3441 3.96575C6.15248 3.96575 6.07508 4.48384 6.07508 5.3128V6.78846H9.05381L8.79416 9.84522H6.07508V19.1429H2.34541V9.84522H0.428467V6.78846H2.34541V4.89771C2.34541 2.30784 3.56263 0.857178 6.61877 0.857178C7.68056 0.857178 8.4577 1.01261 9.46768 1.21985L9.002 4.14678Z" fill="currentColor"/>
        </svg>
    );
};

export default FacebookIconTwo;
