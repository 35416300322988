import React from 'react';
import {Link} from "react-router-dom";
import i18n from "i18next";
import DiagonalArrowIcon from "icons/DiagonalArrowIcon";

import './BoxWprk.scss'

import {useTranslation} from "react-i18next";


const BoxWork = ({item, className}) => {
    const {t} = useTranslation();
    return (
        <div className={`box-work${className ? " " + className : ""}`}>
            <Link to={`/category/${item.slug}`} className="box-work__link">
                <div className="box-work__image">
                    <img src={item.banner} alt={item.banner_alt} className="box-work__image-pic"/>
                </div>
                <div className="box-work__content">
                    <p className="box-work__content-text">
                        {i18n.language === "arm" ? item.name_am : i18n.language === "ru" ? item.name_ru : item.name}
                    </p>
                    <div className="box-work__more-container">
                        <div className="box-work__more">
                            <span>{t("work.more")}</span>
                            <span className="icon-arrow-right">
                                <DiagonalArrowIcon/>
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

export default BoxWork;