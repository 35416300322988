import React from 'react';
import {useTranslation} from "react-i18next";

import image from 'assests/images/business/business.jpg'
import imageWebp from 'assests/images/business/business.webp'
// import ArrowRightIcon from "icons/ArrowRightIcon";

import './Business.scss'
import i18n from "i18next";


const Business = () => {
    const {t} = useTranslation();
    return (
        <section className="section-business" id="sectionBusiness">
            <div className="section-business__inner">
                <div className="section-business__breadcrumb">
                    <p className="section-business__breadcrumb-text">/ {t("business.breadcrumb")}</p>
                </div>
                <div className="section-business__content">
                    <div className="section-business__content-image">
                        <div className="section-business__image-in">
                            <picture>
                                <source srcSet={imageWebp} type="image/webp"/>
                                <img src={image} className="section-business__image-in-pic" alt="business.png"/>
                            </picture>
                        </div>
                    </div>
                    <div className="section-business__content-info">
                        <div className="section-business__info-invest">
                            <h2 className="section-business__invest-title">{t("business.title")}</h2>
                            <div className="section-business__invest-info-text"
                                 dangerouslySetInnerHTML={{__html: t("business.text")}}></div>
                            {/*<div className="section-business__info-more">
                                <a className="section-business__info-more-link" href="#">
                                      <span className="icon-arrow-right">
                                        <ArrowRightIcon/>
                                     </span>
                                    <span>Find out more</span>
                                </a>
                            </div>*/}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Business;
