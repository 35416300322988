import React from 'react';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";

import ArrowRightIcon from "icons/ArrowRightIcon";

import './BoxBlog.scss'
import i18n from "i18next";


const BoxBlog = ({item, className}) => {
    const {t} = useTranslation();
    return (
        <div className={`box-blog${className ? " " + className : ""}`}>
            <NavLink to={`/blog/${item.slug}`} className="box-blog__link">
                <div className="box-blog__image">
                    <img src={item.banner} alt={item.banner_alt} className="box-blog__image-pic"/>
                </div>
                <div className="box-blog__content">
                    <h2 className="box-blog__title">{i18n.language === "arm" ? item.title_am : i18n.language === "ru" ? item.title_ru : item.title}</h2>
                    <div className="box-blog__more">
                        <span className="icon-arrow-right">
                               <ArrowRightIcon/>
                        </span>
                        <span>{t('blog.boxMoreBtn')}</span>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

export default BoxBlog;