import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import i18n from "i18next";
import {useTranslation} from "react-i18next";


import FacebookIconTwo from "icons/FacebookIconTwo";
import TwitterIcon from "icons/TwitterIcon";
import Loader from "components/Loader/Loader";

import {getItem} from "actions/blogAction";

import './BlogDetail.scss'


const BlogDetail = ({getItem}) => {
    const {t} = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const {slug} = useParams();
    const [data, setData] = useState(null);

    const getItemData = async () => {
        const res = await getItem(slug)
        if (res.status === 404) {
            history.push("/pagenotfound");
            return
        }
        setData(res.data)
        document.querySelector('meta[property="og:title"]').content = res.data.title;
        document.querySelector('meta[property="og:image"]').content = res.data.banner;

        document.querySelector('meta[name="twitter:title"]').content = res.data.title;
        document.querySelector('meta[name="twitter:image"]').content = res.data.banner;
    }

    useEffect(() => {
        getItemData()
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (!data) {
        return (
            <section className="section-blog-detail">
                <Loader/>
            </section>
        )
    }

    return (
        <section className="section-blog-detail">
            <div className="section-blog-detail__banner">
                <div className="section-blog-detail__banner-image">
                    <img src={data.banner} alt={data.banner_alt}/>
                    <div className="section-blog-detail__banner-image-overly"></div>
                </div>
                <div className="section-blog-detail__banner-text">
                    <div className="container">
                        <div className="section-blog-detail__banner-text-content">
                            <h3 className="section-blog-detail__banner-text-content-title">
                                {i18n.language === "arm" ? data.title_am : i18n.language === "ru" ? data.title_ru : data.title}
                            </h3>
                            <p className="section-blog-detail__banner-text-content-text">
                                {i18n.language === "arm" ? data.author_am : i18n.language === "ru" ? data.author_ru : data.author}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section-blog-detail__content">
                <div className="container">
                    <div className="section-blog-detail__content-inner">
                        <div className="section-blog-detail__content-share">
                            <div className="section-blog-detail__content-text">
                                <span className="section-blog-detail__content-title">{t('blog.detail.share')}</span>
                                <ul className="list-unstyled section-blog-detail__content-list">
                                    <li className="section-blog-detail__content-item">
                                        <FacebookShareButton url={window.location.href}
                                                             className="section-blog-detail__content-link">
                                            <FacebookIconTwo/>
                                        </FacebookShareButton>
                                    </li>
                                    <li className="section-blog-detail__content-item">
                                        <TwitterShareButton url={window.location.href}
                                                            className="section-blog-detail__content-link is-second">
                                            <TwitterIcon/>
                                        </TwitterShareButton>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="section-blog-detail__content-texts"
                             dangerouslySetInnerHTML={{__html: i18n.language === "arm" ? data.content_am : i18n.language === "ru" ? data.content_ru : data.content}}></div>
                    </div>
                </div>
            </div>
        </section>
    );
};
const mapDispatchToProps = {
    getItem
}

export default connect(null, mapDispatchToProps)(BlogDetail);