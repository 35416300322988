const requestData = (method, url, data = null, upload = false,) => {

    let response = {}

    return fetch('https://api.visitstepanavan.am' + url, {
        crossDomain: true,
        method,
        headers: data ? { "Content-Type": "application/json" } : {},
        body: data ? JSON.stringify(data) : null,
    }).then((res) => {
        Object.assign(response, {status: res.status});
        if (res.status === 204) return '';
        return res.json()
    }).then((res) => {
        Object.assign(response, {data: res});
        return response
    })

};


export default requestData;
