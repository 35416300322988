import React from 'react';

import i18n from "i18next";

import {useTranslation} from "react-i18next";

import './BoxEvent.scss'

const BoxEvent = ({item, className}) => {
    const {t} = useTranslation();
    const date = new Date(item.date);
    const time = item.time;

    return (
        <div className={`box-event${className ? " " + className : ""}`}>
            <a href={item.link} className="box-event__link" target="_blank">
                <div className="box-event__image">
                    <img src={item.banner} alt={item.banner} className="box-event__image-pic"/>
                </div>

                <p className="box-event__date">
                    {t('event.date', {date},)} {time}
                </p>
                <p className="box-event__title">{i18n.language === "arm" ? item.title_am : i18n.language === "ru" ? item.title_ru : item.title}</p>
            </a>
        </div>
    );
};

export default BoxEvent;
