import React, {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

import image from 'assests/images/history/history-image1.jpg'
import image1 from 'assests/images/history/Rectangle1.jpg'
import image2 from 'assests/images/history/Rectangle2.jpg'
import image3 from 'assests/images/history/Rectangle3.jpg'
import image4 from 'assests/images/history/Rectangle4.jpg'

import './History.scss'



const History = () => {
    const location = useLocation();
    const {t} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <section className="section-history-listing">
            <div className="section-history-listing__banner">
                <div className="section-history-listing__banner-image">
                    <img src={image} alt={'history banner'}/>
                    <div className="section-history-listing__banner-image-overly"></div>
                </div>
                <div className="section-history-listing__banner-text">
                    <div className="container">
                        <div className="section-history-listing__banner-text-content" dangerouslySetInnerHTML={{__html: t("history.listing.title") }}></div>
                    </div>
                </div>
            </div>

            <div className="section-history-listing__content">
                <div className="container">
                    <div className="section-history-listing__content-items">
                        <div className="section-history-listing__content-item">
                            <div className="section-history-listing__content-item-image">
                                <img src={image1} alt="image1"/>
                            </div>
                            <div className="section-history-listing__content-item-content is-reverse-content">
                                <h2 className="section-history-listing__content-item-content-title">{t("history.listing.items.item1.title")}</h2>
                                <p className="section-history-listing__content-item-content-text">{t("history.listing.items.item1.description")}</p>
                            </div>
                        </div>
                        <div className="section-history-listing__content-item is-reverse">
                            <div className="section-history-listing__content-item-image">
                                <img src={image2} alt="image2"/>
                            </div>
                            <div className="section-history-listing__content-item-content">
                                <h2 className="section-history-listing__content-item-content-title">{t("history.listing.items.item2.title")}</h2>
                                <p className="section-history-listing__content-item-content-text">{t("history.listing.items.item2.description")}</p>
                            </div>
                        </div>
                        <div className="section-history-listing__content-item">
                            <div className="section-history-listing__content-item-image">
                                <img src={image3} alt="image3"/>
                            </div>
                            <div className="section-history-listing__content-item-content is-reverse-content">
                                <h2 className="section-history-listing__content-item-content-title">{t("history.listing.items.item3.title")}</h2>
                                <p className="section-history-listing__content-item-content-text">{t("history.listing.items.item3.description")}</p>
                            </div>
                        </div>
                        <div className="section-history-listing__content-item is-reverse is-forth">
                            <div className="section-history-listing__content-item-image">
                                <img src={image4} alt="image\4"/>
                            </div>
                            <div className="section-history-listing__content-item-content">
                                <h2 className="section-history-listing__content-item-content-title">{t("history.listing.items.item4.title")}</h2>
                                <p className="section-history-listing__content-item-content-text">{t("history.listing.items.item4.description")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default History;