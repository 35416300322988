import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import {getItems} from "actions/eventAction";

import BoxEvent from "components/Box/BoxEvent/BoxEvent";

import './Events.scss'


const Events = ({events, getItems}) => {

    const {t} = useTranslation();
    const getAllEvents = () => {
        if (!events) {
            getItems()
        }
    }

    useEffect(() => {
        getAllEvents()
    }, []);

    return (
        <section className="section-events" id="sectionEvents">
            <div className="section-events__inner">
                <div className="section-events__breadcrumb">
                    <p className="section-events__breadcrumb-text">/ {t('event.breadcrumb')}</p>
                </div>
                <div className="container">
                    <div className="section-events__container-inner">
                        <div className="section-events__info">
                            <h2 className="section-events__info-title">{t('event.title')}</h2>
                        </div>
                        {events && events.length > 0 ? (
                            <div className="section-events__items">
                                {events && events.map((el, index) => {
                                    return <BoxEvent key={index} item={el} className="section-events__item"/>
                                })}

                            </div>
                        ) : (
                            <div className="section-events__no-event">
                                {t('event.noEvents')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    events: state.event.list
})


const mapDispatchToProps = {
    getItems
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
