import React from 'react';
import {Link} from "react-scroll";
import SimpleBar from 'simplebar-react';

import {useTranslation} from "react-i18next";

import Sidebar from "containers/Layout/Sidebar/Sidebar";
import SocialMedia from "components/SocialMedia/SocialMedia";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import videoSrc from "assests/video/Lori_Dron_Short1.mp4"
import videoImage from "assests/video/Lori_Dron_Short1_Postar.jpg"

import './Banner.scss'


const Banner = () => {
    const {t} = useTranslation();
    return (
        <section className="section-banner">
            <div className="section-banner__video-content">
                <video autoPlay muted loop className="section-banner__video" poster={videoImage}>
                    <source src={videoSrc} type="video/mp4"/>
                </video>
            </div>
            <div className="section-banner__info">
                <div className="section-banner__info-text">
                    <h1 className="section-banner__text-headline">{t('banner.title')}</h1>
                    <p className="section-banner__text-desc">{t('banner.description')}</p>
                </div>
                <div className="section-banner__info-social-media">
                    <SocialMedia title={t('socialMedia.title')}/>
                </div>
                <div className="section-banner__info-scroll-to">
                    <div className="section-banner__info-scroll-to-content">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0.5 0.5 516 516" fill="none"
                             preserveAspectRatio="xMidYMid meet">
                            <g clipPath="url(#1089dece-3437-4091-be33-804fa4e95cfa)">
                                <circle cx="258.5" cy="258.5" r="258" stroke="#C4C4C4" strokeDasharray="8 8"></circle>
                            </g>
                            <defs>
                                <clipPath id="1089dece-3437-4091-be33-804fa4e95cfa">
                                    <rect width="517" height="266" fill="white"></rect>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="section-banner__info-scroll-to-content-info">
                            <span className="section-banner__info-scroll-to-title">{t('banner.more')}</span>
                            <Link className="section-banner__info-scroll-to-btn"
                                  activeClass="is-active"
                                  to="sectinSectionUspBar"
                                  spy={true}
                                  smooth={true}
                                  duration={500}
                            >
                                <ExpandMoreIcon/>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="section-banner__menu">
                    <SimpleBar style={{maxHeight: "100%", width: "100%"}}>
                        <Sidebar/>
                    </SimpleBar>
                </div>
            </div>
        </section>
    );
};

export default Banner;
