import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import {MuiThemeProvider, createTheme} from '@material-ui/core'

import App from './App';
import './styles/index.scss';

const theme = createTheme({
    palette: {
        primary: {
            light: '#273026',
            main: '#394537',
            dark: '#606a5f',
            contrastText: '#fff',
        }
    }
})



ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <App/>
    </MuiThemeProvider>,
    document.getElementById('root')
);