import React, {useEffect, useState} from 'react';
import i18n from "i18next";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {useParams, useLocation, useHistory} from "react-router-dom";

import BoxWorkListing from "components/Box/BoxWorkListing/BoxWorkListing";
import BoxWork from "components/Box/BoxWork/BoxWork";
import Loader from "components/Loader/Loader";

import {getItems, getCategories} from 'actions/categoryAction'


import './Category.scss';

const Category = ({getItems, categories, getCategories}) => {
    const {t} = useTranslation();
    const history = useHistory();
    let {slug} = useParams();
    const [list, setList] = useState(null);
    const [category, setCategory] = useState(null);

    const getAllCategories = async () => {
        if (!categories) {
            await getCategories()
        }
        if (categories) {
            const result = await categories.filter(category => category.slug === slug)
            if (result && result.length <= 0) {
                history.push("/pagenotfound");
            }
            setCategory(result[0])
        }
    }

    const getAllItems = async () => {
        const res = await getItems(slug)
        setList(res.data)
    }

    const location = useLocation();

    useEffect(() => {
        setList(null)
        setCategory(null)
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        getAllCategories()
        getAllItems()
    }, [slug, categories])

    if (!category || !list) {
        return (
            <Loader/>
        )
    }

    return (
        <section className="section-category">
            <div className="section-category__banner">
                <div className="section-category__banner-image">
                    <img src={category.banner} alt={category.banner_alt}/>
                    <div className="section-category__banner-image-overly"></div>
                </div>
                <div className="section-category__banner-text">
                    <div className="container">
                        <div className="section-category__banner-text-content">
                            {i18n.language === "arm" ? category.title_am : i18n.language === "ru" ? category.title_ru : category.title}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-category__content">
                <div className="container">
                    <div className="section-category__content-inner">
                        <div className="section-category__breadcrumb">
                            <p className="section-category__breadcrumb-text">/ {i18n.language === "arm" ? category.name_am : i18n.language === "ru" ? category.name_ru : category.name}</p>
                        </div>
                        <div className="section-category__items">
                            {list ? list.map((item) => {
                                return <BoxWorkListing key={item.id} className={"section-category__item"} item={item}/>
                            }) : ""}
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-category__categories">
                <div className="container">
                    <div className="section-category__categories-items">
                        {categories ? (categories.map((item) => {
                            if (item.slug === slug) return
                            return <BoxWork key={item.id} item={item} className="section-category__categories-item"/>
                        })) : ""}
                    </div>
                </div>
            </div>
        </section>
    );
};
const mapStateToProps = (state) => ({
    list: state.category.list,
    categories: state.category.categories,
})

const mapDispatchToProps = {
    getItems,
    getCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);

