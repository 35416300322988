import React from 'react';
import {Route, Switch} from 'react-router-dom'


import Main from "containers/Main";
import Sections from "containers/Sections/Sections";
import QualityOfLifePage from "containers/Pages/QualityOfLifePage/QualityOfLifePage";
import Category from "containers/Pages/Category/Category";
import PageNotFound from "containers/Pages/PageNotFound/PageNotFound";
import History from "containers/Pages/History/History";
import Blog from "containers/Pages/Blog/Blog";
import BlogDetail from "containers/Pages/Blog/BlogDetail/BlogDetail";


const AppRoutes = () => {


    return (
        <Switch>
            <Route path={["/", "/quality-of-life", "/category/:slug", "/history", '/blog', '/blog/:slug']} exact>
                <Main>
                    <Route exact path="/" component={Sections}/>
                    <Route exact path="/quality-of-life" component={QualityOfLifePage}/>
                    <Route exact path={"/category/:slug"} component={Category}/>
                    <Route exact path={"/history"} component={History}/>
                    <Route exact path={"/blog"} component={Blog}/>
                    <Route exact path={"/blog/:slug"} component={BlogDetail}/>
                </Main>
            </Route>
            <Route path={["*", '/pagenotfound']} component={PageNotFound}/>
        </Switch>
    );
};


export default AppRoutes;
