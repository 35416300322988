import {reduxActionTypes} from './reduxActionTypes'

const initialState = {
    categories: null,
    categoryTabIndex: null,
    list: null
}

const qualityReducer = (state = initialState, action) => {
    switch (action.type) {
        case reduxActionTypes.GET_QUALITY_CATEGORY:
            return {
                ...state,
                categories: action.payload.categories,
            };
        case reduxActionTypes.SET_QUALITY_ITEM_LIST:
            return {
                ...state,
                list: action.payload.list,
            };
        default:
            return state;
    }
}

export default qualityReducer;