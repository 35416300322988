import service from "api/service";

import {reduxActionTypes} from "reducers/reduxActionTypes";

export const getAllItems = () => (dispatch) => {
    return service.blogService.getItems().then((res) => res.data)
}

export const getItems = (take, skip) => (dispatch) => {
    return service.blogService.getItems(take, skip).then((res) => {
        dispatch({
            type: reduxActionTypes.SET_BLOG_ITEM_LIST,
            payload: {
                list: res.data,
            }
        })
    })
}

export const getMoreItems = (take, skip) => (dispatch) => {
    console.log(take,skip)
    return service.blogService.getItems(take, skip).then((res) => {
        dispatch({
            type: reduxActionTypes.SET_BLOG_MORE_ITEM_LIST,
            payload: {
                list: res.data,
            }
        })
    })
}

export const setHasMore = (value) => (dispatch) => {
    dispatch({
        type: reduxActionTypes.SET_BLOG_HAS_MORE,
        payload: {
            hasMore: value,
        }
    })
}

export const getHomeItems = (take, skip) => (dispatch) => {
    return service.blogService.getItems(take, skip).then((res) => {
        dispatch({
            type: reduxActionTypes.SET_BLOG_ITEM_HOME_LIST,
            payload: {
                homeList: res.data,
            }
        })
    })
}

export const getItem = (slug) => () => {
    return service.blogService.getItem(slug).then((res) => res)
}
