import React from 'react';

import {useTranslation} from "react-i18next";

import MultiItemSlider from "components/MultiItemSlider/MultiItemSlider";

import {whyStepanavanData} from 'data-mockup/whyStepnavan-mockup'

import './WhyStepanavan.scss'




const WhyStepanavan = () => {

    const {t} = useTranslation();

    const sliderOptions = {
        type: 'loop',
        perPage: 2.5,
        perMove: 1,
        gap: '37px',
        fixedHeight: 472,
        pagination: true,
        rewind: true,
        cover: true,
        updateOnMove: false,
        focus: 0,
        breakpoints: {
            575: {
                focus: 'center',
                fixedHeight: 280,
                gap: '20px',
                perPage: 1.5,
            },
            767: {
                focus: 'center',
                fixedHeight: 300,
                gap: '20px',
            },
            991: {
                focus: 'center',
                fixedHeight: 380,
                gap: '20px',
            },
            1199: {
                fixedHeight: 380,
                gap: '20px',
            },
        },
    };

    return (
        <section className="section-why-stepanavan" id="sectionWhyStepanavan">
            <div className="section-why-stepanavan__inner">
                <div className="section-why-stepanavan__breadcrumb">
                    <p className="section-why-stepanavan__breadcrumb-text">/ {t('whyStepanavan.breadcrumb')}</p>
                </div>
                <div className="section-why-stepanavan__content">
                    <div className="section-why-stepanavan__info">
                        <div className="section-why-stepanavan__info-content">
                            <h2 className="section-why-stepanavan__info-title">{t('whyStepanavan.title')}</h2>
                            <p className="section-why-stepanavan__info-desc">{t('whyStepanavan.description')}</p>
                        </div>
                    </div>
                    <div className="section-why-stepanavan__slider">
                        <MultiItemSlider options={sliderOptions} items={whyStepanavanData}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyStepanavan;
