import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";

import BoxWork from "components/Box/BoxWork/BoxWork";
import {getCategories} from "actions/categoryAction";

import './Work.scss'


const Work = ({categories, getCategories}) => {
    const {t} = useTranslation();
    const getAllCategories = () => {
        if (!categories) {
            getCategories()
        }
    }

    useEffect(() => {
        getAllCategories()
    }, []);

    return (
        <section className="section-work" id="sectionSectionWork">
            <div className="section-work__inner">
                <div className="section-work__breadcrumb">
                    <p className="section-work__breadcrumb-text">/ {t('work.breadcrumb')}</p>
                </div>
                <div className="section-work__info">
                    <h2 className="section-work__info-heading">{t('work.title')}</h2>
                    <p className="section-work__info-text">{t('work.description')}</p>
                </div>
                <div className="section-work__items">
                    {categories && categories.map((el, index) => {
                        return <BoxWork key={index} item={el} className="section-work__item"/>
                    })}
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    categories: state.category.categories
})


const mapDispatchToProps = {
    getCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(Work);
