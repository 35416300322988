import service from "api/service";

import {reduxActionTypes} from "reducers/reduxActionTypes";

export const getItems = () => (dispatch) => {
    return service.eventService.getItems().then((res) => {
        dispatch({
            type: reduxActionTypes.SET_EVENT_ITEM_LIST,
            payload: {
                list: res.data,
            }
        })
    })
}

