import {reduxActionTypes} from './reduxActionTypes'

const initialState = {
    list: null,
    homeList: null,
    hasMore: false,
}

const blogReducer = (state = initialState, action) => {
    switch (action.type) {
        case reduxActionTypes.SET_BLOG_ITEM_HOME_LIST:
            return {
                ...state,
                homeList: action.payload.homeList,
            };
        case reduxActionTypes.SET_BLOG_ITEM_LIST:
            return {
                ...state,
                list: action.payload.list,
            };
        case reduxActionTypes.SET_BLOG_HAS_MORE:
            return {
                ...state,
                hasMore: action.payload.hasMore,
            };
        case reduxActionTypes.SET_BLOG_MORE_ITEM_LIST:
            return {
                ...state,
                list: [...state.list, ...action.payload.list],
            };
        default:
            return state;
    }
}

export default blogReducer;