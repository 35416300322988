
export const navigationListOne = [
    {
        to: '/#sectionWhyStepanavan',
        title:"navigation.item1",
    },
    {
        to: '/#sectionSectionWork',
        title: "navigation.item2",
    },
    {
        to: '/#sectionCultureAndHistory',
        title: "navigation.item3",
    },
    {
        to: '/#sectionExplore',
        title: "navigation.item4",
    }
]

export const navigationListTwo = [
    {
        to: '/#sectionBusiness',
        title: "navigation.item5",
    },
    {
        to: '/#sectionEvents',
        title: "navigation.item6",
    },
    {
        to: "/#sectionBlog",
        title: "navigation.item7",
    },
    {
        to: '/quality-of-life',
        title: "navigation.item8",
    },
    {
        to: '/#sectionContact',
        title: "navigation.item9",
    }
]