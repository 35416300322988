import React from 'react';
import {useTranslation} from "react-i18next";
import OneItemSlider from "components/OneItemSlider/OneItemSlider";

import {exploreData} from 'data-mockup/explore-mockup'

import './Explore.scss'


const Explore = () => {
    const {t} = useTranslation();
    const sliderOptions = {
        type: 'loop',
        perPage: 1.2,
        perMove: 1,
        pagination: true,
        rewind: true,
        updateOnMove: false,
        focus: 0,
    };

    return (
        <section className="section-explore" id="sectionExplore">
            <div className="section-explore__breadcrumb">
                <p className="section-explore__breadcrumb-text">/ {t("explore.breadcrumb")}</p>
            </div>
            <div className="section-explore__content">
                <OneItemSlider items={exploreData} options={sliderOptions}/>
            </div>
        </section>
    );
};

export default Explore;
