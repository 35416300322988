import React from 'react';

import {useTranslation} from "react-i18next";


import './Video.scss'

const Video = () => {
    const {t} = useTranslation();

    return (
        <section className="section-video">

            <div className="container">
                <div className="section-video__inner">
                    <div className="section-video__content">
                        <div className="section-video__title">
                            <h2>{t('video.title')}</h2>
                        </div>
                        <div className="section-video__video">
                            <iframe src="https://www.youtube.com/embed/tfjhNyrx7u0"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    )
};

export default Video;