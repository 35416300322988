import React from 'react';

import Banner from "./Banner/Banner";
import UspBar from "./UspBar/UspBar";
import WhyStepanavan from "./WhyStepanavan/WhyStepanavan";
import Video from "./Video/Video";
import Journey from "./Journey/Journey";
import Work from "./Work/Work";
import History from "./History/History";
import Explore from "./Explore/Explore";
import Business from "./Business/Business";
import Events from "./Events/Events";
import Blog from "./Blog/Blog";
import Contact from "./Contact/Contact";


const Sections = () => {
    return (
        <>
            <Banner/>
            <UspBar/>
            <WhyStepanavan/>
            <Video/>
            <Journey/>
            <Work/>
            <History/>
            <Explore/>
            <Business/>
            <Events/>
            <Blog/>
            <Contact/>
        </>
    );
};

export default Sections;
