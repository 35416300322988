import React from 'react';

import i18n from "i18next";

import './BoxWorkListing.scss'

import {useTranslation} from "react-i18next";

const BoxWorkListingContent = ({item}) => {
    const {t} = useTranslation();
    return (
        <div className="box-work-listing__container">
            <div className="box-work-listing__image">
                <img src={item.banner} alt={item.ibanner_alt} className="box-work-listing__image-pic"/>
            </div>
            <div className="box-work-listing__content">
                <div className="box-work-listing__content-title">
                    {i18n.language === "arm" ? item.title_am : i18n.language === "ru" ? item.title_ru : item.title}
                </div>
                <div className="box-work-listing__content-desc">
                    {i18n.language === "arm" ? item.content_am : i18n.language === "ru" ? item.content_ru : item.content}
                </div>
                <div className="box-work-listing__content-link">
                    {t("boxWorkListing.more")}
                </div>
            </div>
        </div>
    )
}

const BoxWorkListing = ({item, className}) => {
    return (
        <div className={`box-work-listing${className ? " " + className : ""}`}>
            {item.link ? (
                    <a href={item.link} target="_blank" className="box-work-listing__link">
                        <BoxWorkListingContent item={item}/>
                    </a>) :
                (
                    <BoxWorkListingContent item={item}/>
                )
            }
        </div>
    );
};

export default BoxWorkListing;