import React from 'react';
import PropTypes from 'prop-types';
import {Splide, SplideSlide} from "@splidejs/react-splide";

import './MultiItemSlider.scss'

const MultiItemSlider = ({options, items}) => {

    const newOptions = {
        ...options,
        classes: {
            // Add classes for arrows.
            arrows: 'splide__arrows multi-item-slider__arrows',
            arrow: 'splide__arrow multi-item-slider__arrow',
            prev: 'splide__arrow--prev multi-item-slider__prev',
            next: 'splide__arrow--next multi-item-slider__next',

            // Add classes for pagination.
            pagination: 'splide__pagination multi-item-slider__pagination', // container
            page: 'splide__pagination__page multi-item-slider__page', // each button
        },
        arrowPath: 'M10.8711 40C10.3909 40.0009 10.0009 39.6122 10 39.132C9.99959 38.9005 10.0915 38.6783 10.2555 38.5148L28.7715 20.0005L10.2555 1.48628C9.91547 1.14627 9.91547 0.595031 10.2555 0.255013C10.5955 -0.0850044 11.1467 -0.0850044 11.4868 0.255013L30.6167 19.3849C30.9561 19.7245 30.9561 20.2749 30.6167 20.6145L11.4868 39.7444C11.3237 39.908 11.1021 40 10.8711 40Z'
    }

    return (
        <Splide
            options={newOptions} className="multi-item-slider">
            {items.map((el, index) => {
                return (
                    <SplideSlide key={index} className="multi-item-slider__item" >
                        <img src={el.img.src} alt={el.img.alt} className="multi-item-slider__item-image"/>
                    </SplideSlide>
                )
            })}
        </Splide>
    );
};

MultiItemSlider.propTypes = {
    options: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
}

export default MultiItemSlider;


