import React from 'react';

import {useTranslation} from "react-i18next";

import ParkIcon from "icons/ParkIcon";
import PlanetEarthIcon from "icons/PlanetEarthIcon";
import AgronomyIcon from "icons/AgronomyIcon";
import InternetIcon from "icons/InternetIcon";
import SafeCityIcon from "icons/SafeCityIcon";
import AffordableLifeIcon from "icons/AffordableLifeIcon";

import './UspBar.scss'

const uspBarList = [
    {
        title: "uspBar.item1",
        icon: <ParkIcon/>
    },
    {
        title: "uspBar.item2",
        icon: <PlanetEarthIcon/>
    },
    {
        title: "uspBar.item3",
        icon: <AgronomyIcon/>
    },
    {
        title: "uspBar.item4",
        icon: <InternetIcon/>
    },
    {
        title: "uspBar.item5",
        icon: <SafeCityIcon/>
    },
    {
        title: "uspBar.item6",
        icon: <AffordableLifeIcon/>
    },

]

const UspBar = () => {
    const {t} = useTranslation();

    return (
        <section className="section-uspbar" id="sectinSectionUspBar">

            <div className="container">
                <div className="section-uspbar__inner">
                    <div className="section-uspbar__items">
                        {uspBarList.map((item, index) => {
                            return (
                                <div key={index} className="section-uspbar__item">
                                    <div className="section-uspbar__item-content">
                                        <span className="section-uspbar__item-icon">
                                            {item.icon}
                                        </span>
                                        <span className="section-uspbar__item-title">{t(item.title)}</span>
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
        </section>
    );
};

export default UspBar;
