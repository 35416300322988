import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import './Loader.scss'

const Loader = ({size, className}) => {
    return (
        <div className={`app-loader${className ? " " + className : ""}`}>
            <div className="app-loader-container">
                <CircularProgress size={size}/>
            </div>
        </div>
    );
};

export default Loader;
