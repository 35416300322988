import categoryService from './categoryService'
import contactService from './contactService'
import qualityService from './qualityService'
import eventService from './eventService'
import blogService from './blogService'


const service = {
    categoryService,
    contactService,
    qualityService,
    eventService,
    blogService
}

export default service;