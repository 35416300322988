import React from 'react';

const InstagramIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.93007 11.9184C11.0257 11.9184 11.9189 11.0268 11.9189 9.93038C11.9189 9.49758 11.7773 9.09798 11.5429 8.77158C11.1817 8.27078 10.5945 7.94238 9.93127 7.94238C9.26767 7.94238 8.68087 8.27038 8.31887 8.77118C8.08367 9.09758 7.94327 9.49718 7.94287 9.92998C7.94167 11.0264 8.83407 11.9184 9.93007 11.9184Z" fill="currentColor"/>
            <path d="M14.2712 7.49838V5.83198V5.58398L14.022 5.58478L12.3564 5.58998L12.3628 7.50478L14.2712 7.49838Z" fill="currentColor"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M0 9.9304C0 4.4548 4.4548 0 9.9304 0C15.4064 0 19.8608 4.4548 19.8608 9.9304C19.8608 15.4056 15.4056 19.8608 9.9304 19.8608C4.4548 19.8608 0 15.4056 0 9.9304ZM15.578 13.3956V8.7716V6.466C15.578 5.262 14.5988 4.2832 13.3948 4.2832H6.4656C5.2616 4.2832 4.2828 5.262 4.2828 6.466V8.7716V13.3956C4.2828 14.6 5.2616 15.5788 6.4656 15.5788H13.3952C14.5988 15.5788 15.578 14.6 15.578 13.3956Z" fill="currentColor"/>
            <path d="M13.0196 9.93041C13.0196 11.6332 11.634 13.0196 9.93041 13.0196C8.22681 13.0196 6.84161 11.6332 6.84161 9.93041C6.84161 9.52081 6.92321 9.12921 7.06881 8.77161H5.38281V13.3956C5.38281 13.9932 5.86801 14.4772 6.46521 14.4772H13.3944C13.9908 14.4772 14.4768 13.9932 14.4768 13.3956V8.77161H12.79C12.9368 9.12921 13.0196 9.52081 13.0196 9.93041Z" fill="currentColor"/>
        </svg>

    );
};

export default InstagramIcon;
