import React from 'react';

const DiagonalArrowIcon = () => {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.6097 26.5544L22.7377 14.4264L22.7377 25.5678L25.5579 25.5578V9.61307H9.61313L9.61313 12.4233L20.7446 12.4333L8.61658 24.5613L10.6097 26.5544Z"
                fill="currentColor"/>
        </svg>
    );
};

export default DiagonalArrowIcon;
