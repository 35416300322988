import React from 'react';
import {useHistory} from "react-router-dom";

import Header from "containers/Layout/Header/Header";
import OffCanvasSidebar from "containers/Layout/OffCanvasSidebar/OffCanvasSidebar";

import './PageNotFound.scss'

const PageNotFound = () => {
    const history = useHistory();

    return (
        <>
            <Header/>
            <OffCanvasSidebar/>
            <div className="page-not-found">
                <div className="page-not-found__info">
                    <h1 className="page-not-found__info-title">error 404</h1>
                    <p className="page-not-found__info-text">YOU NEED TO GO BACK <a onClick={() => {
                        history.goBack();
                    }} className="page-not-found__info-text-link">HOME</a></p>
                </div>
            </div>
        </>
    );
};

export default PageNotFound;