import React, {useEffect} from 'react';
import {connect} from "react-redux"
import {NavLink} from "react-router-dom";

import {useTranslation} from "react-i18next";

import BoxBlog from "components/Box/BoxBlog/BoxBlog";
import DiagonalArrowIcon from "icons/DiagonalArrowIcon";

import {getHomeItems} from 'actions/blogAction'

import Loader from "components/Loader/Loader";

import './Blog.scss'


const Blog = ({list, getHomeItems}) => {
    const {t} = useTranslation();
    const getItemList = async () => {
        if (!list) {
            await getHomeItems(3,0)
        }
    }

    useEffect(() => {
        getItemList()
    }, []);

    return (

        <section className="section-blog" id="sectionBlog">
            <div className="section-blog__inner">
                <div className="section-blog__breadcrumb">
                    <p className="section-blog__breadcrumb-text">/ {t('blog.breadcrumb')}</p>
                </div>
                <div className="container">
                    <div className="section-blog__container-inner">
                        <div className="section-blog__content">
                            {!list ? (
                                <Loader/>
                            ) : (
                                list.length <= 0 ? (
                                    <div className="section-blog__coming-soon">
                                        {t('blog.notBlogs')}
                                    </div>
                                ) : (
                                    <>
                                        <div className="section-blog__content-info">
                                            <h2 className="section-blog__info-title">{t('blog.title')}</h2>
                                        </div>

                                        <div className="section-blog__items">

                                            {list.map((el, index) => {
                                                return <BoxBlog key={index} item={el}
                                                                className="section-blog__item"/>
                                            })}

                                            <div className="section-blog__buttons">
                                                <NavLink className="section-blog__button-link" to="/blog">
                                                    <span className="section-blog__button-link-text">{t('blog.homeMoreBtn')}</span>
                                                    <span className="icon-arrow-right">
                                             <DiagonalArrowIcon/>
                                        </span>
                                                </NavLink>
                                            </div>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

const mapStateToProps = (state) => ({
    list: state.blog.homeList
})

const mapDispatchToProps = {
    getHomeItems
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);