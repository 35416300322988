import React from 'react';
import i18n from "i18next";


import {Button, ButtonGroup} from '@material-ui/core';

import './Languages.scss'


const Languages = () => {
    const root = document.documentElement;

    const onChange = (val) => {
        i18n.changeLanguage(val)
        root?.style.setProperty("--title-black-font", val === "arm" || val === "ru" ? "NotoSerifArmenian-ExtraCondensedBlack" : "Koutura");
        root?.style.setProperty("--title-font", val === "arm" || val === "ru" ? "NotoSerifArmenian-ExtraCondensedBold" : "Koutura");
        root?.style.setProperty("--text-font", val === "arm" || val === "ru" ? "NotoSansArmenian-Regular" : "Geomanist-Regular");
        root?.style.setProperty("--text-light-font", val === "arm" || val === "ru" ? "NotoSansArmenian-Light" : "Geomanist-Light");
        root?.style.setProperty("--text-extra-light-font", val === "arm" || val === "ru" ? "NotoSansArmenian-ExtraLight" : "Geomanist-ExtraLight");
    }

    return (
        <div className="languages">
            <ButtonGroup variant="text" color="primary" className="languages-items">
                <Button className="languages-btn" onClick={() => {
                    onChange("en")
                }}>en</Button>
                <Button className="languages-btn" onClick={() => {
                    onChange("ru")
                }}>ru</Button>
                <Button className="languages-btn" onClick={() => {
                    onChange("arm")
                }}>arm</Button>
            </ButtonGroup>
        </div>
    );
};

export default Languages;
