import service from "api/service";

import {reduxActionTypes} from "reducers/reduxActionTypes";

export const getCategories = () => (dispatch) => {
    return service.categoryService.getCategory().then((res) => {
        if (res && res.status === 200) {
            dispatch({
                type: reduxActionTypes.GET_CATEGORY,
                payload: {
                    categories: res.data,
                }
            })
        } else {
            return res
        }
    })
}

export const getItems = (id) => () => {
    return service.categoryService.getItems(id).then((res) => {
        return res
    })
}
