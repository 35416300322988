import React, {useEffect} from 'react';

import { useLocation, NavLink } from "react-router-dom";

import IconButton from '@material-ui/core/IconButton';

import Languages from "components/Languages/Languages";

import MenuIcon from "icons/MenuIcon";
import LogoImageWithe from "assests/logo/logo-white.svg"

import './Header.scss'
import Logo from "../Logo/Logo";


const Header = () => {
    const location = useLocation();

    const openMenu = (e) => {
        let sidebarContent = document.getElementsByClassName('sidebar-main');

        if (sidebarContent[0].classList.contains('is-open')) {
            e.currentTarget.classList.remove('is-open')
            sidebarContent[0].classList.remove('is-open')
            document.body.classList.remove('no-scroll')
        } else {
            e.currentTarget.classList.add('is-open')
            sidebarContent[0].classList.add('is-open')
            document.body.classList.add('no-scroll')
        }
    }

    const handleResize = () => {
        let navBtn = document.getElementsByClassName('menu-trigger'),
            sidebarContent = document.getElementsByClassName('sidebar-main'),
            headerContent = document.getElementsByClassName('header-main');

        if (window.innerWidth >= 992 && sidebarContent[0].classList.contains('is-open') && headerContent[0].classList.contains('is-homepage')) {
            navBtn[0].classList.remove('is-open')
            sidebarContent[0].classList.remove('is-open')
            document.body.classList.remove('no-scroll')
        }
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize);


        return () => window.removeEventListener("resize", handleResize);
    }, []);


    return (
        <header className={`header-main ${location.pathname === '/' ? 'is-homepage': ''}`}>
            <div className="header-main__inner">
                <div className="header-main__logo">
                    <Logo imgSrc={LogoImageWithe} className="header-main__logo-link"/>
                </div>
                <div className="header-main__navigation">
                    <div className="header-main__languages">
                        <Languages/>
                    </div>
                    <div className="header-main__menu-btn">
                        <IconButton onClick={(e) => {
                            openMenu(e)
                        }} className="menu-trigger">
                            <span className="menu-trigger-icon">
                                <MenuIcon/>
                           </span>
                        </IconButton>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
