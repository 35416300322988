import React from 'react';
import SimpleBar from 'simplebar-react';

import {useTranslation} from "react-i18next";

import Sidebar from "../Sidebar/Sidebar";

import Languages from "components/Languages/Languages";
import SocialMedia from "components/SocialMedia/SocialMedia";

import './OffCanvasSidebar.scss'


const OffCanvasSidebar = () => {
    const {t} = useTranslation();
    return (
        <div className="sidebar-main off-canvas">
            <SimpleBar style={{maxHeight: "100vh", width: "100%"}}>
                <div className="sidebar-main__content">
                    <div className="sidebar-main__navigation">
                        <Sidebar/>
                    </div>
                    <div className="sidebar-main__info">
                        <div className="sidebar-main__info-languages">
                            <Languages/>
                        </div>
                        <div className="sidebar-main__line">

                        </div>
                        <div className="sidebar-main__info-social-media">
                            <SocialMedia title={t('socialMedia.title')}/>
                        </div>
                    </div>
                </div>
            </SimpleBar>
        </div>
    );
};

export default OffCanvasSidebar;
