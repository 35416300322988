import {createStore, combineReducers, applyMiddleware} from "redux"
import reduxThunk from "redux-thunk"

import categoryReducer from './categoryReducer';
import qualityReducer from "./qualityReducer";
import eventReducer from "./eventReducer";
import blogReducer from "./blogReducer";

const reducers = combineReducers({
    category: categoryReducer,
    quality: qualityReducer,
    event: eventReducer,
    blog: blogReducer,
});


const initialState = {
    category: {
        categories: null,
        categoryTabIndex: 1,
        list: null
    },
    quality: {
        categories: null,
        categoryTabIndex: null,
        list: null
    },
    event: {
        list: null
    },
    blog: {
        list: null,
        homeList: null,
        hasMore: false,
    }
};

export const store = createStore(reducers, initialState, applyMiddleware(reduxThunk));