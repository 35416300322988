import React from 'react';

const FailIcon = () => {
    return (
        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM58.8284 58.8284C57.2663 60.3905 54.7337 60.3905 53.1716 58.8284L40 45.6569L26.8284 58.8284C25.2663 60.3905 22.7337 60.3905 21.1716 58.8284C19.6095 57.2663 19.6095 54.7337 21.1716 53.1716L34.3431 40L21.1716 26.8284C19.6095 25.2663 19.6095 22.7337 21.1716 21.1716C22.7337 19.6095 25.2663 19.6095 26.8284 21.1716L40 34.3432L53.1716 21.1716C54.7337 19.6095 57.2663 19.6095 58.8284 21.1716C60.3905 22.7337 60.3905 25.2663 58.8284 26.8284L45.6569 40L58.8284 53.1716C60.3905 54.7337 60.3905 57.2663 58.8284 58.8284Z"
                  fill="#CE1728"/>
        </svg>

    );
}

export default FailIcon;